<template>
    <div class="card">
        <b-spinner variant="primary" v-if="isSpinner" large class="pageLoadSpinners"/>
        <div v-if="!isSpinner">
            <div class="m-2">
                <b-row class="mb-1">
                    <b-col cols="12" md="6"            
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <h4 class="table_title" style="padding:0px;">Sent Notification</h4>
                    </b-col>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-end mb-1 mb-md-0">
                        <v-select
                            v-model="perPage"
                            style="width: 90px"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="pageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                    </b-col>
                </b-row>
            </div>
            <b-table
                striped
                hover
                responsive
                show-empty
                v-model="applicationTable"
                empty-text="No matching records found"
                :per-page="perPage"
                :current-page="currentPage"
                :items="notificationList"
                :fields="fields"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="position-relative promotionalNotification"
            >
                <template #cell(id)="data">
                    {{((currentPage-1) * perPage + data.index + 1)>9?'':0}}{{(currentPage-1) * perPage + data.index + 1}}
                </template>
                <template #cell(title)="data">
                    <div>
                        {{data.item.title}}
                    </div>
                </template>

                <template #cell(discription)="data">
                    {{data.item.discription}}
                </template>

                <template #cell(reciver)="data">
                    <p :title="data.item.userNames">
                        {{reciverName(data.item.reciver)}}
                    </p>
                </template>
                <template #cell(sendtime)="data">
                        {{data.item.sendtime}}
                </template>
                <template #cell(attachment)="data">
                        {{data.item.attachment}}
                        <a :href="data.item.imageUrl" target="blank" v-if="data.item.attachment !== 'N/A'">
                          <feather-icon
                            icon="EyeIcon"
                            size="16"
                        />
                        </a>
                </template>
                <!-- <template #cell(action)="data">
                    <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        type="submit"
                        variant="primary"
                        class="mr-1"
                        @click="resendNotification(data.item)"
                    >
                        Resend
                    </b-button>
                </template> -->
            </b-table>
            <div class="mx-2 mb-2">
                <div class="row">
                    <b-col md="6" class="d-flex align-items-center">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ perPage }} of {{ notificationList.length }} entries</span>
                        <!-- <span class="text-muted mt-1">Showing {{ 1 }} to {{ perPage }} of {{ userData.employeeDocs.length }} entries</span> -->
                    </b-col>
                    <b-col  md="6">
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="notificationList.length"
                            :per-page="perPage"
                            align="right"
                            first-number
                            last-number
                            prev-class="prev-item"
                            next-class="next-item"
                            class="mt-1 mb-0 custom_pagination"
                        >
                        <template #prev-text>
                            <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                            />
                        </template>
                        <template #next-text>
                            <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                            />
                        </template>
                        </b-pagination>
                    </b-col>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
import {
  BCard,BFormInput,BFormGroup,BForm,BRow,BCol,BButton,BTabs,BTab,
  BCardText,BFormSelect,BFormDatepicker,BFormRadio,BFormFile,BSpinner,
  BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive'
import firebase from "@/utils/firebaseInit.js";
const db = firebase.firestore()
import { dbCollections } from "@/utils/firebaseCollection.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import moment from 'moment';
export default {
   components: {
        BCard,
        BCardText,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormSelect,
        BFormDatepicker,
        BFormRadio,
        BFormFile,
        BTabs,
        BTab,
        BSpinner,
        BTable, BAvatar, BBadge, BPagination, BInputGroup, BInputGroupAppend, BImg,
        vSelect,
        Ripple
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            perPage: 10,
            pageOptions: [10, 25, 50],
            applicationTable:[],
            totalRows: 1,
            currentPage: 1,
            filter: null,
            isSpinner:false,
            filterOn: [],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: '',
            },
            fields: [
                {
                    key: 'title', label: 'Title',sortable: true
                },
                {
                    key: 'discription', label: 'Discription',sortable: true
                }, 
                {
                    key: 'reciver', label: 'Receiver',sortable: true
                }, 
                {
                    key: 'sendtime', label: 'Sent Time',sortable: true
                }, 
                {
                    key: 'attachment', label: 'Attachment',sortable: true
                }, 
                // {
                //     key: 'user', label: 'User Name',
                // },
                // {
                //     key: 'action', label: 'Action',
                // },
            ],
            notificationList:[],
        }
    },
    methods: {
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        reciverName(data){
            if (data == 'all') {
                return 'All User'
            }
            if (data == 'guest') {
                return 'Guest'
            }
            if (data == 'student') {
                return 'All Student'
            }
            if (data == 'custom') {
                return 'Custom'
            }
        },
        resendNotification(data){
            try {
                let notificationObj = {
                    title: data.title,
                    description: data.discription,
                    type: data.reciver,
                    promotionalId: data.id
                }
                if (data.reciver == 'custom') {
                    let id = [];
                    data.userIds.forEach((data)=>{
                        id.push(data.id);
                    });
                    notificationObj.userIds = id
                }
                this.isSpinner = true;
                this.$axios.post(dbCollections.APIURL+'api/v1/promotional',notificationObj).then((resp)=>{
                    if (resp.data.status) {
                        this.isSpinner = false;
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Notification resent successfully.',
                                icon: 'CoffeeIcon',
                                variant: 'success',                               
                            },
                        });
                    } else {
                        this.isSpinner = false;
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: 'Notification not resent',
                                icon: 'CoffeeIcon',
                                variant: 'danger',                               
                            },
                        });
                    }
                }).catch((error)=>{
                    console.error(error);
                    this.isSpinner = false;
                });
            } catch (error) {
                this.isSpinner = false;
                console.error(error);
            }
        }
    },
    computed: {
        dataMeta(){
            const localItemsCount = this.applicationTable ? this.applicationTable.length : 0
            return {
                from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
                to: this.perPage * (this.currentPage - 1) + localItemsCount,
                of: this.notificationList.length,
            }
        },
        // reciverName(data){
        //     if (data == 'all') {
        //         return 'All User'
        //     }
        //     if (data == 'guest') {
        //         return 'Guest'
        //     }
        //     if (data == 'student') {
        //         return 'All Student'
        //     }
        //     if (data == 'custom') {
        //         return 'Custom'
        //     }
        // },
    },
    created() {
        try {     
            this.isSpinner = true;
            db.collection(dbCollections.POMOTIONALNOTIFICATION).get().then((resp) => {
                if (resp.empty) {
                    this.isSpinner = false;
                    console.error(resp);
                    return;
                }
                resp.docs.forEach((data)=>{
                    let obj = {
                        title : data.data().title ? data.data().title : '',
                        discription : data.data().discription ? data.data().discription : '',
                        reciver : data.data().reciver ? data.data().reciver : '',
                        userIds : data.data().userId ? data.data().userId : [],
                        userNames : data.data().userId ? data.data().userId.map(data=> data.name) : [],
                        attachment: data.data().attachmentName ? data.data().attachmentName : 'N/A',
                        imageUrl : data.data().attachment ? data.data().attachment : '',
                        id: data.data().id ? data.data().id : '',
                        sendtime: data.data().createdAt ? moment(new Date(data.data().createdAt.seconds * 1000)).format("DD-MM-YYYY HH:mm") : "",
                    }
                    this.notificationList.push(obj);
                    this.isSpinner = false;
                });
            }).catch((error) => {
                this.isSpinner = false;
                console.error(error);
            });
        } catch (error) {
            console.error(error);
            this.isSpinner = false;
        }
    },
}
</script>